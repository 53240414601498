"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _I18nProvider = require("./I18nProvider");
Object.keys(_I18nProvider).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _I18nProvider[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _I18nProvider[key];
    }
  });
});
var _interpolate = require("./interpolate");
Object.keys(_interpolate).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _interpolate[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _interpolate[key];
    }
  });
});
var _Trans = require("./Trans");
Object.keys(_Trans).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Trans[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Trans[key];
    }
  });
});