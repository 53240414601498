"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interpolate = void 0;
const interpolate = function (format) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return format.replace(/{{\s*(\w+)\s*}}/g, (match, key) => {
    return options[key] || match;
  });
};
exports.interpolate = interpolate;